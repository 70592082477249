import { isNotBlank, useQueryString } from '@segunosoftware/equinox';
import type { ParsedQuery } from 'query-string';
import { useEffect, useState } from 'react';

function isValidParam(param: unknown): param is string {
	return typeof param === 'string' && isNotBlank(param);
}

function getLocaleParameter(parsedQueryString: ParsedQuery<string>): string | undefined {
	if (isValidParam(parsedQueryString.locale)) {
		return parsedQueryString.locale;
	}
	return undefined;
}

export function useLocaleParam() {
	const parsed = useQueryString();
	const localeParam = getLocaleParameter(parsed);
	const [locale, setLocale] = useState(localeParam);

	useEffect(() => {
		if (localeParam) {
			setLocale(localeParam);
		}
	}, [localeParam]);

	return locale;
}
