export const ACCOUNT = 'ACCOUNT';
export const CACHED_ACCOUNT = 'CACHED_ACCOUNT';

export const COUNTRIES = 'COUNTRIES';

export const CUSTOMER_SEGMENT_SEARCH = 'CUSTOMER_SEGMENT_SEARCH';

export const DISCOUNT_CODES = 'DISCOUNT_CODES';
export const DISCOUNT_CODE_COUNT = 'DISCOUNT_CODE_COUNT';

export const DISCOUNT_SETS = 'DISCOUNT_SETS';
export const DISCOUNT_SET_SEARCH = 'DISCOUNT_SET_SEARCH';
export const DISCOUNT_SETS_COUNT = 'DISCOUNT_SETS_COUNT';
export const DISCOUNT_SETS_BY_PRICE_RULE = 'DISCOUNT_SETS_BY_PRICE_RULE';
export const DISCOUNT_SETS_MULTI_FETCH = 'DISCOUNT_SETS_MULTI_FETCH';

export const EXPORT_JOBS = 'EXPORT_JOBS';

export const IMPORT_ERRORS = 'IMPORT_ERRORS';

export const PRICE_RULES = 'PRICE_RULES';
