import { Loading, LoadingScreen } from '@segunosoftware/equinox';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import GA4UserProperties from './components/GA4UserProperties';
import I18nProvider from './components/I18nProvider';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useLocaleParam } from './hooks/useLocaleParam';
import { CreateDiscountSet, DiscountSetList, EditDiscountSet, EditPriceRule, Welcome } from './utils/lazy-load';

export default function App() {
	const locale = useLocaleParam();
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapper>
				<Suspense fallback={<LoadingScreen />}>
					<Routes>
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbed locale={locale} />} />} />
					</Routes>
				</Suspense>
			</SentryWrapper>
		</AppProvider>
	);
}

type AppEmbedProps = {
	locale?: string;
};

function AppEmbed({ locale }: AppEmbedProps) {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<I18nProvider localeParam={locale}>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="external/*" element={<ExternalLinks />} />
					<Route path="new" element={<CreateDiscountSet />} />
					<Route path="edit/:discountSetId" element={<EditDiscountSet />} />
					<Route path="edit-price-rule/:priceRuleId" element={<EditPriceRule />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="" element={<DiscountSetList />} />
				</Routes>
			</Suspense>
			<SupportChat />
			<GA4UserProperties />
			{calculatedLoading && <Loading />}
		</I18nProvider>
	);
}

function ExternalLinks() {
	const [searchParams] = useSearchParams();

	return (
		<Routes>
			<Route path="new" element={<Navigate to="/new" />} />
			<Route path="edit" element={<Navigate to={`/edit-price-rule/${searchParams.get('id')}`} />} />
		</Routes>
	);
}
